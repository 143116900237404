import { defineStore } from 'pinia';
import {
  getSurvey,
  getSurveyEnrollments,
  fetchSurveys,
} from '/@/app/services/surveys';
import { IEnrollment, ISurvey } from '/@/app/types/interfaces';

interface Submission {}

export const tagColors = {
  משפחה: 'purple',
  'בית ספר חברתי': 'default',
  'בית ספר לימודי': 'blue',
  'חוץ בית הספר': 'pink',
  'נושאים מסכמים': 'green',
};

export const useSurveyStore = defineStore({
  id: 'survey',
  state: () => ({
    survey: {} as ISurvey,
    surveys: [] as ISurvey[],
    enrollments: [] as IEnrollment[],
    submissions: [] as Submission[],
  }),
  actions: {
    fetchSurvey(id) {
      const { onSuccess } = getSurvey(id);
      onSuccess(({ data }) => {
        this.survey = data;
      });
      return { onSuccess };
    },
    init({ survey, group }) {
      let { asPromised: enrollmentRequest } = getSurveyEnrollments({
        survey,
        group,
      });
      enrollmentRequest().then(data => {
        this.enrollments = data.data;
      });
      return this.enrollments;
    },
    fetchSurveys() {
      const { onSuccess } = fetchSurveys();
      onSuccess(({ data }) => {
        this.surveys = data
          ?.filter(({ published }) => published)
          ?.map(survey => ({ ...survey, type: 'survey' }));
      });
    },
    fetchSurveyEnrollments({ group, survey }) {
      const { onSuccess } = getSurveyEnrollments({ group, survey });
      onSuccess(({ data }) => {
        this.surveys = data
          ?.filter(({ published }) => published)
          ?.map(survey => ({ ...survey, type: 'survey' }));
      });
    },
  },
});
